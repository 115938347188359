import type { AppProps } from "next/app";
import Script from "next/script";
import { ToastContainer } from "react-toastify";
import { Layout } from "../components/layout/layout.comp";
import { GlobalStyles } from "../styles/styles.config";
import Head from "next/head";
import { ParallaxProvider } from "react-scroll-parallax";

import "react-toastify/dist/ReactToastify.css";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      {/* GTM */}
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NFP4CKHH');`,
        }}
      ></Script>
      <Script
        id="ga-script-src"
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-FQTQQ1J3G3"
      ></Script>
      <Script
        id="ga-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-FQTQQ1J3G3');`,
        }}
      ></Script>
      <GlobalStyles />
      <ParallaxProvider>
        <Layout>
          <Component {...pageProps} />
          <ToastContainer />
        </Layout>
      </ParallaxProvider>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NFP4CKHH"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
}
