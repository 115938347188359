import Image from 'next/image';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { Section } from '../section/section.comp';
import { StyledDiv } from './trustedBy.styles';

export const TrustedBy = ({ style = {} }: { style?: CSSProperties; }) => {
    return (
        <Section style={style}>
            <StyledDiv>
                <p>Trusted By</p>
                <div className="companies-wrapper">
                    <Image src={'/assets/billboard-logo.webp'} alt='Billboard logo' width={154} height={32} />
                    <Image src={'/assets/cnbc-logo.webp'} alt='CNBC logo' width={83} height={60} />
                    <Image src={'/assets/blizzard-logo.webp'} alt='Blizzard logo' width={115} height={60} />
                    <Image src={'/assets/barstool-logo.webp'} alt='Barstool Sports logo' width={197} height={60} />
                    <Image src={'/assets/vox-logo.webp'} alt='Vox Media logo' width={237} height={32} />
                    <Image src={'/assets/nato-logo.webp'} alt='NATO logo' width={192} height={60} />
                    {/* <Image src={'/assets/field-logo.webp'} alt='Field Museum logo' width={60} height={60} /> */}
                    <Image src={'/assets/acs-logo.webp'} alt='ACS logo' width={205} height={40} />
                    {/* <Image src={'/assets/usa-logo.webp'} alt='USA logo' width={45} height={60} /> */}
                    <Image src={'/assets/cbc-logo.webp'} alt='CBC logo' width={173} height={43.98} />
                    <Image src={'/assets/wayfair-logo.webp'} alt='wayfair logo' width={175} height={38.04} />
                    <Image src={'/assets/panasonic-logo.webp'} alt='Panasonic logo' width={210} height={32} />
                    <Image src={'/assets/atlassian-logo.webp'} alt='Atlassian logo' width={258} height={32} />
                    <Image src={'/assets/fandom-logo.webp'} alt='Fandom logo' width={169} height={42} />
                    <Image src={'/assets/benq-logo.webp'} alt='Benq logo' width={157} height={45} />
                    <Image src={'/assets/bytedance-logo.webp'} alt='Bytedance logo' width={244} height={42} />

                    <Image src={'/assets/fifa-logo.webp'} alt='Fifa logo' width={136} height={45} />
                    <Image src={'/assets/pwc-logo.webp'} alt='PWC logo' width={79} height={60} />
                    <Image src={'/assets/papaya-logo.webp'} alt='Papaya logo' width={205} height={42} />
                    <Image src={'/assets/qatar-logo.webp'} alt='Qatar Airways logo' width={185} height={52} />
                    <Image src={'/assets/nbc-logo.webp'} alt='NBC logo' width={184} height={45} />
                    <Image src={'/assets/nexon-logo.webp'} alt='Nexon logo' width={148} height={45} />

                </div>
            </StyledDiv>
        </Section>
    );
};