import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { CustomLink } from '../link/link.comp';
import { Section } from '../section/section.comp';
import { StyledDiv } from './supportedPlatforms.styles';

export const SupportedPlatforms = ({ style = {} }: { style?: CSSProperties; }) => {
    return (
        <Section style={style}>
            <StyledDiv>
                <h2>Compatible With All Site Builders, E-Commerce
                    Platforms and Websites</h2>
                <div className="companies-wrapper">
                    <Link href={'/platforms/webflow'}>
                        <Image src={'/assets/platforms/Webflow.webp'} alt='webflow logo' width={160} height={40} />
                    </Link>
                    <Link href={'/platforms/shopify'}>
                        <Image src={'/assets/platforms/Shopify.webp'} alt='shopify logo' width={140} height={40} />
                    </Link>
                    <Link href={'/platforms/wix'}>
                        <Image src={'/assets/platforms/Wix.webp'} alt='wix logo' width={102} height={40} />
                    </Link>
                    <Link href={'/platforms/wordpress'}>
                        <Image src={'/assets/platforms/Wordpress.webp'} alt='wordpress logo' width={176} height={40} />
                    </Link>
                    <Link href={'/platforms/bigcommerce'}>
                        <Image src={'/assets/platforms/Bigcommerce.webp'} alt='bigcommerce logo' width={176} height={40} />
                    </Link>
                    <Link href={'/platforms/squarespace'}>
                        <Image src={'/assets/platforms/Squarespace.webp'} alt='squrespace logo' width={216} height={30} />
                    </Link>
                    <Link href={'/platforms/weebly'}>
                        <Image src={'/assets/platforms/Weebly.webp'} alt='weebly logo' width={116} height={40} />
                    </Link>
                    <Link href={'/platforms/joomla'}>
                        <Image src={'/assets/platforms/Joomla.webp'} alt='joomla logo' width={196} height={40} />
                    </Link>
                    <Link href={'/platforms/duda'}>
                        <Image src={'/assets/platforms/Duda.webp'} alt='duda logo' width={94} height={40} />
                    </Link>
                    <Link href={'/platforms/jimdo'}>
                        <Image src={'/assets/platforms/Jimdo.webp'} alt='jimdo logo' width={108} height={40} />
                    </Link>
                    <Link href={'/platforms/magento'}>
                        <Image src={'/assets/platforms/Magento.webp'} alt='magento logo' width={144} height={40} />
                    </Link>
                    <Link href={'/platforms/shift4shop'}>
                        <Image src={'/assets/platforms/Shift4shop.webp'} alt='shift4hsop logo' width={215} height={30} />
                    </Link>
                    <Link href={'/platforms/unbounce'}>
                        <Image src={'/assets/platforms/Unbounce.webp'} alt='unbounce logo' width={174} height={40} />
                    </Link>
                    <Link href={'/platforms/carrd'}>
                        <Image src={'/assets/platforms/Carrd.webp'} alt='carrd logo' width={126} height={40} />
                    </Link>
                </div>
                <CustomLink className='show-more' href='/platforms' title='View all supported platforms'>View all supported platforms
                    <IoIosArrowForward className='icon' />
                </CustomLink>
            </StyledDiv>
        </Section>
    );
};